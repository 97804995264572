<template>
    <div id="app">
        <OfflineWarning v-if="isOffline" />

        <LoadingOverlay v-if="isDataLoading" />

        <div v-else class="base-layout l-stack">
            <SimulationModeBar v-if="isSimulationMode" />

            <AppToolbar v-if="isToolbarVisible" />

            <router-view />
        </div>

        <VNotifications position="bottom right" />

        <VNotifications position="bottom center" group="center" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppToolbar from './components/redesigned/AppToolbar'
import GlobalSocketHelper from './mixins/GlobalSocketHelper'
import LoadingOverlay from './components/redesigned/LoadingOverlay'
import OfflineWarning from './components/OfflineWarning'
import SimulationModeBar from './components/SimulationModeBar'
import TranslationHelper from './mixins/TranslationHelper'

export default {
    name: 'App',
    components: {
        AppToolbar,
        LoadingOverlay,
        OfflineWarning,
        SimulationModeBar,
    },
    mixins: [GlobalSocketHelper, TranslationHelper],
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapState('common', ['isDataLoading']),
        ...mapState('map', ['simpleMarkersEnabled']),
        ...mapGetters('auth', ['isSimulationMode']),
        ...mapGetters('tracker', ['isTrackerCountHigh']),
        isToolbarVisible() {
            return (
                this.$route.name !== 'login' &&
                this.$route.name !== 'messageStream' &&
                this.$route.name !== 'passwordReset'
            )
        },
    },
    watch: {
        '$i18n.locale': {
            immediate: true,
            handler() {
                document.documentElement.lang = this.$i18n.locale
            },
        },
        '$route.params.token'() {
            this.loadSharingData({
                token: this.$route.params.token,
            })
        },
        isTrackerCountHigh() {
            if (this.isTrackerCountHigh && !this.simpleMarkersEnabled) {
                this.setSimpleMarkersEnabledIfNotStored(true)
            }
        },
        userInfo: {
            immediate: true,
            handler() {
                if (this.userInfo) {
                    window.FreshworksWidget?.('identify', 'ticketForm', {
                        name: `${this.userInfo.first_name} ${this.userInfo.last_name}`.trim(),
                        email: this.userInfo.email,
                    })
                }
            },
        },
    },
    async mounted() {
        await this.loadData()
    },
    methods: {
        ...mapActions('common', ['loadData']),
        ...mapActions('sharing', ['loadSharingData']),
        ...mapMutations('map', ['setSimpleMarkersEnabledIfNotStored']),
    },
}
</script>

<style lang="scss">
@import './styles/base.scss';
@import './styles/context-menu.scss';
@import './styles/datepicker.scss';
@import './styles/detachedpanel.scss';
@import './styles/forms.scss';
@import './styles/layout.scss';
@import './styles/map.scss';
@import './styles/marker.scss';
@import './styles/modal.scss';
@import './styles/multiselect.scss';
@import './styles/notifications.scss';
@import './styles/quill.scss';
@import './styles/radio.scss';
@import './styles/redesigned.scss';
@import './styles/slider.scss';
@import './styles/timepicker.scss';
@import './styles/tooltip.scss';
@import './styles/utilities.scss';

#app {
    color: $color-text;
    font-family: 'IBM Plex Sans', sans-serif;

    & > .base-layout {
        width: 100vw;
        height: 100vh;
    }
}
</style>
