import axios from 'axios'
import qs from 'qs'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

import router from '@/router'
import store from '@/store'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
})

axiosInstance.interceptors.request.use(async config => {
    if (store.state.auth.jwt) {
        const jwt = await store.dispatch('auth/refreshTokenIfNecessary')
        if (jwt?.access && !config.headers.Authorization) {
            config.headers.Authorization = `JWT ${jwt.access}`
        }
    }
    return config
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 403) {
            Sentry.captureEvent({
                message: 'Forced user log out',
            })
            store.dispatch('auth/logout')
            router.push({ name: 'login' })
            window.location.reload()
        }
        return Promise.reject(error)
    }
)

const get = (url, config = {}) => {
    return axiosInstance.get(url, config)
}

const getRecursively = async (url, config = {}) => {
    let response = await get(url, config)
    while (response.data.next) {
        const prevResults = response.data.results
        response = await get(response.data.next)
        response.data.results.unshift(...prevResults)
    }
    return response
}

const post = (url, body, config = {}) => {
    return axiosInstance.post(url, body, config)
}

const put = (url, body, config = {}) => {
    return axiosInstance.put(url, body, config)
}

const patch = (url, body, config = {}) => {
    return axiosInstance.patch(url, body, config)
}

const drop = (url, config = {}) => {
    return axiosInstance.delete(url, config)
}

const getOverwrittenConfig = async () => {
    const tokenOverwritten = JSON.parse(localStorage.getItem('JWT-overwritten'))

    if (!tokenOverwritten) {
        return undefined
    }

    let accessToken

    if (moment.unix(jwtDecode(tokenOverwritten.access).exp).isAfter()) {
        accessToken = tokenOverwritten.access
    } else if (moment.unix(jwtDecode(tokenOverwritten.refresh).exp).isAfter()) {
        try {
            const { data } = await axios.post(
                process.env.VUE_APP_JWT_API_URL + 'refresh/',
                JSON.stringify({ refresh: tokenOverwritten.refresh }),
                { headers: { 'Content-Type': 'application/json' } }
            )

            if (data.access) {
                localStorage.setItem('JWT-overwritten', JSON.stringify(data))
                accessToken = data.access
            }
        } catch (error) {
            return undefined
        }
    }

    return accessToken
        ? { headers: { Authorization: `JWT ${accessToken}` } }
        : undefined
}

const parseError = error => {
    if (error.response?.data) {
        if (error.response.data.detail) {
            return error.response.data.detail
        }
        return Object.values(error.response.data)
            .reduce((acc, val) => {
                return [...acc, ...val]
            }, [])
            .join(', ')
    }
}

export default {
    axiosInstance,
    get,
    getRecursively,
    post,
    put,
    drop,
    patch,
    getOverwrittenConfig,
    parseError,
}
