import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import common from './modules/common'
import createLocation from './modules/createLocation'
import dashboard from './modules/dashboard'
import location from './modules/location'
import lsva from './modules/lsva'
import maintenance from './modules/maintenance'
import map from './modules/map'
import sharing from './modules/sharing'
import tracker from './modules/tracker'
import trip from './modules/trip'
import user from './modules/user'
import role from './modules/role'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        common,
        createLocation,
        dashboard,
        location,
        lsva,
        maintenance,
        map,
        sharing,
        tracker,
        trip,
        user,
        role,
    },
})
