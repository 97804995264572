import moment from 'moment-timezone'

function getWeekday(date, locale = 'en') {
    if (!date) {
        return null
    }
    const momentDate = moment(date)
    return momentDate.isValid()
        ? momentDate.locale(locale).format('dddd')
        : null
}

function hoursAndMinutesDuration(seconds) {
    if (typeof seconds !== 'number') {
        return null
    }
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.round(seconds / 60) - hours * 60
    return hours
        ? minutes
            ? `${hours}h ${minutes}min`
            : `${hours}h`
        : `${minutes}min`
}

// Converts a number to 1k representative.
// Taken from https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900.
function kFormatter(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
        : Math.sign(num) * Math.abs(num)
}

function formatDistance(distance = 0, fractionDigits = 2) {
    return distance >= 1000
        ? `${(distance / 1000).toFixed(fractionDigits)}km`
        : `${distance}m`
}

export default {
    getWeekday,
    hoursAndMinutesDuration,
    kFormatter,
    formatDistance,
}
