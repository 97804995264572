<template>
    <div>
        <textarea
            v-if="textarea"
            ref="input"
            v-bind="$attrs"
            class="base-input"
            :class="{
                'base-input--block': block,
            }"
            v-on="inputListeners"
        />

        <label
            v-else
            class="base-input"
            :class="{
                'base-input--block': block,
                'base-input--clearable': clearable,
                'base-input--disabled': $attrs.disabled,
                'base-input--error': isError,
                'base-input--with-icon': $slots.icon,
                'base-input--with-postfix': $slots.postfix,
                'base-input--with-prefix': $slots.prefix,
            }"
        >
            <slot name="icon" />

            <span v-if="$slots.prefix" class="base-input__prefix">
                <slot name="prefix" />
            </span>

            <input ref="input" v-bind="$attrs" v-on="inputListeners" />

            <span v-if="clearable" class="base-input__clear">
                <RemoveIcon width="1em" height="1em" @click="handleClear" />
            </span>

            <span v-if="$slots.postfix" class="base-input__postfix">
                <slot name="postfix" />
            </span>
        </label>
        <div v-if="isError && errorText" class="base-input__error-text">
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash'

import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'BaseInput',
    components: {
        RemoveIcon,
    },
    inheritAttrs: false,
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        debounce: {
            type: Number,
            default: undefined,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        trim: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: '',
        },
    },
    computed: {
        inputListeners() {
            return {
                ...this.$listeners,
                input: this.debounce
                    ? debounce(this.handleInput, this.debounce)
                    : this.handleInput,
            }
        },
    },
    methods: {
        blur() {
            this.$refs.input.blur()
        },
        focus() {
            this.$refs.input.focus()
        },
        handleClear() {
            this.$refs.input.value = ''
            this.$emit('input', '')
        },
        handleInput(event) {
            const value = this.trim
                ? event.target.value.trim()
                : event.target.value
            this.$emit('input', value)
        },
    },
}
</script>

<style lang="scss" scoped>
.base-input {
    position: relative;
    display: block;
    background-color: $color-gray-lighter-new;
    border-radius: 4px;

    & > svg {
        position: absolute;
        top: 12px;
        left: 12px;
        cursor: text;
    }

    input {
        @include input-style;
    }

    &--block {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: $block-shadow;

        input {
            @include input-style-block;
            box-shadow: none;
        }
    }

    &--disabled {
        opacity: 0.75;
    }

    &--error {
        outline: 1px solid $color-error;
    }

    &--with-icon {
        input {
            padding-left: 38px;
        }
    }

    &--clearable,
    &--with-postfix,
    &--with-prefix {
        display: flex;
    }

    &--with-postfix {
        input {
            padding-right: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-align: right;

            &::placeholder {
                text-align: left;
            }
        }
    }

    &--with-prefix {
        input {
            padding-left: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__clear {
        display: flex;
        align-items: center;
        padding-right: 12px;

        & > svg {
            cursor: pointer;
            transition: opacity 0.1s;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    &__error-text {
        color: $color-error;
        font-size: 11px;
        padding-top: 4px;
    }

    &__postfix,
    &__prefix {
        margin: 0;
        padding: 12px;
        line-height: 16px;
        font-size: 14px;
        color: $color-text-new;
        white-space: nowrap;
        cursor: text;
    }

    &__postfix {
        padding-left: 0;
    }

    &__prefix {
        padding-right: 0;
    }
}

textarea {
    &.base-input {
        @include input-style;
        min-width: 100%;
        max-width: 100%;
        min-height: 40px;

        &--block {
            @include input-style-block;
        }
    }
}
</style>
