import 'mapbox-gl-leaflet'

import { mapboxGL, tileLayer } from 'leaflet'

const defaultSettings = {
    activeLayerId: null,
    clusteringEnabled: true,
    displayDriverName: true,
    displayRemainingTime: true,
    liveUpdatesEnabled: true,
    showAccuracy: false,
    showLabels: false,
    showLocations: true,
    simpleMarkersEnabled: false,
}

const storedSettings = JSON.parse(localStorage.getItem('mapSettings'))

const initialSettings = { ...defaultSettings, ...storedSettings }

const updateStoredSettings = state => {
    const settings = Object.keys(initialSettings).reduce(
        (acc, key) => ({ ...acc, [key]: state[key] }),
        {}
    )
    localStorage.setItem('mapSettings', JSON.stringify(settings))
}

const state = {
    activeLayerId: initialSettings.activeLayerId,
    clusteringEnabled: initialSettings.clusteringEnabled,
    displayDriverName: initialSettings.displayDriverName,
    displayRemainingTime: initialSettings.displayRemainingTime,
    heatmapData: null,
    lastUserPosition: null,
    liveUpdatesEnabled: initialSettings.liveUpdatesEnabled,
    mapInstance: null,
    navigationRoute: null,
    positionTrackingEnabled: false,
    savedBounds: null,
    showAccuracy: initialSettings.showAccuracy,
    showLabels: initialSettings.showLabels,
    showLocations: initialSettings.showLocations,
    simpleMarkersEnabled: initialSettings.simpleMarkersEnabled,
    tileProviders: [
        {
            id: 'standard',
            traceColor: '#13365e',
            traceBorderColor: null,
            layer: tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}' +
                    '?access_token=' +
                    process.env.VUE_APP_MAPBOX_TOKEN,
                { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
            ),
        },
        {
            id: 'outdoor',
            traceColor: '#000',
            traceBorderColor: null,
            layer: tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}' +
                    '?access_token=' +
                    process.env.VUE_APP_MAPBOX_TOKEN,
                { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
            ),
        },
        {
            id: 'street',
            traceColor: '#000',
            traceBorderColor: null,
            layer: tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/navigation-preview-day-v4/tiles/{z}/{x}/{y}' +
                    '?access_token=' +
                    process.env.VUE_APP_MAPBOX_TOKEN,
                { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
            ),
        },
        {
            id: 'satellite',
            traceColor: '#03fc07',
            traceBorderColor: '#000',
            layer: tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}' +
                    '?access_token=' +
                    process.env.VUE_APP_MAPBOX_TOKEN,
                { maxZoom: 20, tileSize: 512, zoomOffset: -1 }
            ),
        },
        {
            id: 'swisstopo',
            traceColor: '#03fc07',
            traceBorderColor: '#000',
            layer: tileLayer(
                'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
                { maxZoom: 20, tileSize: 256 }
            ),
        },
        {
            id: 'swisstopo-hiking',
            traceColor: '#03fc07',
            traceBorderColor: '#000',
            layer: tileLayer(
                'https://wmts20.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
                { maxZoom: 19, tileSize: 256 }
            ),
            maxZoom: 19,
        },
        {
            id: 'vectormap',
            traceColor: '#a4c7f5',
            traceBorderColor: '#516677',
            layer: mapboxGL({
                accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
                style: 'mapbox://styles/mapbox/light-v10?optimize=true',
            }),
            maxZoom: 23,
        },
        {
            id: 'vectormap-dark',
            traceColor: '#a4c7f5',
            traceBorderColor: '#516677',
            layer: mapboxGL({
                accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
                style: 'mapbox://styles/mapbox/dark-v10?optimize=true',
            }),
            maxZoom: 23,
        },
    ],
}

const getters = {
    activeLayer(state) {
        return (
            state.tileProviders.find(({ id }) => id === state.activeLayerId) ||
            {}
        )
    },
    defaultLayer(state) {
        let defaultTileProvider = state.tileProviders[0]

        if (process.env.VUE_APP_DEFAULT_MAP) {
            const defaultMapId = process.env.VUE_APP_DEFAULT_MAP
            defaultTileProvider =
                state.tileProviders.find(({ id }) => id === defaultMapId) ||
                defaultTileProvider
        }

        return defaultTileProvider
    },
    liveUpdatesEnabledConditionally(state, getters, rootState, rootGetters) {
        return rootGetters['tracker/isTrackerCountHigh']
            ? state.liveUpdatesEnabled
            : true
    },
    simpleMarkersEnabledConditionally(state, getters, rootState, rootGetters) {
        return rootGetters['tracker/isTrackerCountHigh']
            ? state.simpleMarkersEnabled
            : false
    },
}

const actions = {}

const mutations = {
    addHeatmapData(state, data) {
        if (!state.heatmapData) {
            state.heatmapData = []
        }

        state.heatmapData.push(...data)
    },
    clearSavedBounds(state) {
        state.savedBounds = null
    },
    resetHeatmapData(state) {
        state.heatmapData = null
    },
    saveBounds(state, data) {
        state.savedBounds = data
    },
    setActiveLayerId(state, data) {
        state.activeLayerId = data
        updateStoredSettings(state)
    },
    setMapInstance(state, data) {
        state.mapInstance = data
    },
    setNavigationRoute(state, data) {
        state.navigationRoute = data
    },
    setPositionTrackingEnabled(state, data) {
        state.positionTrackingEnabled = data
    },
    setUserPosition(state, data) {
        state.lastUserPosition = data
    },
    switchClusteringEnabled(state) {
        state.clusteringEnabled = !state.clusteringEnabled
        updateStoredSettings(state)
    },
    switchDisplayDriverName(state) {
        state.displayDriverName = !state.displayDriverName
        updateStoredSettings(state)
    },
    switchDisplayRemainingTime(state) {
        state.displayRemainingTime = !state.displayRemainingTime
        updateStoredSettings(state)
    },
    switchLiveUpdates(state) {
        state.liveUpdatesEnabled = !state.liveUpdatesEnabled
        updateStoredSettings(state)
    },
    switchShowAccuracy(state) {
        state.showAccuracy = !state.showAccuracy
        updateStoredSettings(state)
    },
    switchShowLabels(state) {
        state.showLabels = !state.showLabels
        updateStoredSettings(state)
    },
    switchShowLocations(state) {
        state.showLocations = !state.showLocations
        updateStoredSettings(state)
    },
    setSimpleMarkersEnabledIfNotStored(state, data) {
        if (typeof storedSettings?.simpleMarkersEnabled !== 'boolean') {
            state.simpleMarkersEnabled = data
            updateStoredSettings(state)
        }
    },
    switchSimpleMarkersEnabled(state) {
        state.simpleMarkersEnabled = !state.simpleMarkersEnabled
        updateStoredSettings(state)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
