// This are global translations. All other translations are made in the components!
export default {
    en: {
        shared: {
            language: {
                de: 'German',
                en: 'English',
                fr: 'French',
                it: 'Italian',
            },
            measurementOptions: {
                battery_status: {
                    critical: 'Critical',
                    ok: 'Ok',
                    warning: 'Warning',
                },
                button_mode: {
                    1: 'Short press',
                    2: 'Long press',
                    3: 'Double press',
                },
            },
            measurements: {
                adblue_level: 'AdBlue level',
                ai1: 'Analog input - Channel 1',
                ai2: 'Analog input - Channel 2',
                ai3: 'Analog input - Channel 3',
                ai4: 'Analog input - Channel 4',
                ai5: 'Analog input - Channel 5',
                ai6: 'Analog input - Channel 6',
                ai7: 'Analog input - Channel 7',
                ai8: 'Analog input - Channel 8',
                altitude: 'Altitude',
                angle: 'Angle',
                barometric_pressure: 'Barometric pressure',
                battery: 'Battery',
                battery_status: 'Battery status',
                battery_voltage: 'Battery voltage',
                brightness: 'Brightness',
                button_mode: 'Button input',
                co2: 'CO2',
                custom: 'Custom',
                d1: 'State - Channel 1',
                d2: 'State - Channel 2',
                d3: 'State - Channel 3',
                d4: 'State - Channel 4',
                d5: 'State - Channel 5',
                d6: 'State - Channel 6',
                distance: 'Distance',
                distance_to_service: 'Distance to service',
                dtc_codes: 'DTC Codes',
                energy_0: 'Energy',
                ev_battery_health: 'EV battery health',
                ev_battery_percentage: 'EV battery percentage',
                ev_charging_state: 'EV charging state',
                ignition: 'Ignition',
                movement: 'Movement',
                ev_remaining_distance: 'EV remaining distance',
                external_voltage: 'External voltage',
                fill_level: 'Fill level',
                fuel_consumption: 'Fuel consumption',
                fuel_level: 'Fuel level',
                fuel_rate: 'Fuel rate',
                fuel_total: 'Total fuel used',
                geofence: 'Geofence',
                humidity: 'Relative humidity',
                humidity_absolute: 'Absolute humidity',
                level: 'Level',
                lightning_average_distance: 'Lightning average distance',
                lightning_strike_count: 'Lightning strike count',
                lower_loop: 'Lower loop',
                maintenance: 'Maintenance',
                mass: 'Mass',
                mass_brine: 'Mass of brine',
                mass_salt: 'Mass of salt',
                mass_sand: 'Mass of sand',
                maximum_wind_speed: 'Maximum wind speed',
                moisture: 'Moisture',
                odometer: 'Odometer',
                offline: 'Offline',
                online: 'Online',
                potential: 'Potential',
                power: 'Power',
                precipitation: 'Precipitation',
                precipitation_absolute: 'Precipitation absolute',
                precipitation_intensity: 'Precipitation intensity',
                probe_state: 'Probe State',
                regex: 'Regex',
                running_time: 'Operating time',
                sensor_attached: 'Sensor attached',
                solar_radiation: 'Solar radiation',
                speed: 'Speed',
                spread_density_brine: 'Spread density of brine',
                spread_density_salt: 'Spread density of salt',
                spread_density_sand: 'Spread density of sand',
                spread_distance: 'Mileage of the spreading',
                spread_running_time: 'Spread running time',
                spread_width: 'Spread width',
                spreading_on: 'Spreading state',
                state: 'State',
                t1: 'Temperature - Channel 1',
                t2: 'Temperature - Channel 2',
                t3: 'Temperature - Channel 3',
                t4: 'Temperature - Channel 4',
                t5: 'Temperature - Channel 5',
                t6: 'Temperature - Channel 6',
                tachograph_odometer: 'Tachograh odometer',
                tau: 'Tau',
                temperature: 'Temperature',
                upper_loop: 'Upper loop',
                vapor_pressure: 'Vapor pressure',
                voc: 'VOC',
                voltage: 'Voltage',
                voltage_0: 'Voltage - Channel 1',
                voltage_1: 'Voltage - Channel 2',
                voltage_2: 'Voltage - Channel 3',
                voltage_3: 'Voltage - Channel 4',
                voltage_4: 'Voltage - Channel 5',
                volume: 'Volume',
                water_level: 'Water level',
                wind_direction: 'Wind direction',
                wind_speed: 'Wind speed',
            },
            tachograph: {
                time_related_state: {
                    0: 'Normal',
                    1: '15min before 4.5h',
                    2: '4.5h reached',
                    3: '15min before 9h',
                    4: '9h reached',
                    5: '15min before 16h',
                    6: '16h reached',
                    7: 'Weekly driving time pre-warning active',
                    8: 'Weekly driving time warning active',
                    9: '2 weeks driving time pre-warning active',
                    10: '2 weeks driving time warning active',
                    11: 'Driver 1 card expiry warning active',
                    12: 'Next mandatory driver 1 card download warning active',
                    13: 'Other',
                    14: 'Error',
                    15: 'Time related state',
                },
                working_state: {
                    0: 'Rest',
                    1: 'Driver available',
                    2: 'Work',
                    3: 'Drive',
                    6: 'Error',
                    7: 'Not available',
                    8: 'Daily rest',
                },
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Compressor trailer',
                'default-gasser': 'Various',
                'material-container': 'Material container',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu': 'SBB recycling station aluminium',
                'sbb-bin-paper': 'SBB recycling station paper',
                'sbb-bin-pet': 'SBB recycling station PET',
                'sbb-bin-waste': 'SBB recycling station waste',
                'skip-bin': 'Skip bin',
                'team-container': 'Team container',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Waste dump',
                'wheel-loader': 'Wheel Loader',
                Traktor: 'Tractor',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Car',
                compressor: 'Compressor',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Roller',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            weekdays: {
                Mon: 'Mon',
                Tue: 'Tue',
                Wed: 'Wed',
                Thu: 'Thu',
                Fri: 'Fri',
                Sat: 'Sat',
                Sun: 'Sun',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Cancel',
            lastMeasurement: 'Last measurement',
            no: 'No',
            noOptions: 'No options available',
            noSearchResult: 'No search result',
            save: 'Save',
            yes: 'Yes',
            success: 'Success',
            error: 'Error',
            timeRange: '{from} - {to}',
            lsva: {
                tripCreated: 'Trip created',
                correctionCreated: 'Correction created',
                columns: {
                    type: 'Type',
                    name: 'Name',
                    vin: 'VIN',
                    duration: 'Duration',
                    distance: 'Distance',
                    drives: 'Drives',
                    endOfRegistration: 'End of Registration',
                    message: 'Message',
                },
                truckStatus: {
                    ALL: 'All',
                    VEHICLE_OUTSIDE_TOLL_DOMAIN: 'Drive abroad',
                    GNSS_WITH_TRIP: 'Drive in Switzerland',
                    GNSS_WITHOUT_TRIP: 'No drive',
                    WITHOUT_ACCESS: 'No contact with tracker',
                    NO_DRIVE: 'No drive',
                },
                trailerTypes: {
                    S: 'Semi-trailer',
                    T: 'Trailer',
                },
                tripTypes: {
                    REGULAR: 'Regular',
                    TRACK: 'Track',
                    JOURNEY: 'Journey',
                    TRAILER: 'Trailer',
                },
            },
            units: {
                tons: 'tons',
                kg: 'kg',
                km: 'km',
                hour: '{n} hour | {n} hours',
                minute: '{n} minute | {n} minutes',
            },
            reason: 'Reason',
        },
        router: {
            accel_y: 'Collection',
            adblue_level: 'AdBlue level',
            allAssets: 'All Assets',
            animalactivitychart: 'Activity',
            assetBeaconHistoryChart: 'Beacon history',
            assetConnectionHeatmap: 'Connection Heatmap',
            assetGatewayHistoryChart: 'Gateway history',
            assetLocationHistoryChart: 'Location History',
            assetScheme: 'Schema',
            assetTypeAssets: '{items} of type {assetType}',
            assetTypes: 'Types of assets',
            barometric_pressure: 'Barometric pressure',
            battery: 'Battery',
            battery_percentage: 'State of Charge',
            battery_voltage: 'Battery voltage',
            brightness: 'Brightness',
            changePassword: 'Change Password',
            charts: 'Charts',
            chartsCombined: 'Combined chart',
            clueyLeakageSensors: 'Sensors',
            clueyLeakage_ai2: 'Sensor 1',
            clueyLeakage_ai3: 'Sensor 2',
            clueyLeakage_ai4: 'Sensor 3',
            clueyLeakage_ai5: 'Sensor 4',
            clueyLeakage_ai6: 'Sensor 5',
            clueyLeakage_ai7: 'Sensor 6',
            clueyLeakage_ai8: 'Sensor 7',
            cm2humiditychart: 'Temperature and humidity',
            co2: 'Carbon dioxide',
            connectionHeatmap: 'Connection Heatmap',
            createlocation: 'New Location',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            d3: 'Channel 3',
            dashboardSettings: 'Settings',
            detail: 'Details',
            distance: 'Distance',
            dynamicbinchart: 'Fill Level',
            ecocoach_d1_count: 'Power up',
            ecocoach_energy_0: 'Remaining energy',
            ecocoach_temperature_0: 'Battery temperature',
            ecocoach_temperature_1: 'AFE temperature',
            ecocoach_voltage_0: 'Battery voltage',
            editAsset: 'Edit Asset',
            editlocation: 'Edit Location',
            energy_0: 'Energy',
            ev_battery_health: 'EV battery health',
            ev_battery_percentage: 'EV battery percentage',
            ev_charging_state: 'EV charging state',
            ignition: 'Ignition',
            movement: 'Movement',
            external_voltage: 'External voltage',
            fenceGuardStatusChart: 'Fence Voltage Status',
            fenceGuardVoltage: 'Fence Voltage',
            fenceGuard_voltage_0: 'Wire 1',
            fenceGuard_voltage_1: 'Wire 2',
            fenceGuard_voltage_2: 'Wire 3',
            fenceGuard_voltage_3: 'Wire 4',
            fenceGuard_voltage_4: 'Wire 5',
            fencyBoy_d1_count: 'Impulses',
            fillLevel: 'Fill level',
            fill_level: 'Fill level',
            fuel_consumption: 'Fuel consumption',
            fuel_level: 'Fuel level',
            fuel_rate: 'Fuel rate',
            globalAnimalActivityChart: 'Activity',
            humidity: 'Relative humidity',
            humidity_absolute: 'Absolute humidity',
            level: 'Level',
            lightning_average_distance: 'Lightning average distance',
            lightning_strike_count: 'Lightning strike count',
            location: 'Location',
            locationAnimalActivityChart: 'Activity',
            locationConnectionHeatmap: 'Connection Heatmap',
            locationDetailAssetDetail: 'Details',
            locationHistoryChart: 'Location History',
            locationhistory: 'Location history',
            lostAssets: 'Left zone',
            lowBatteryAssets: 'Low battery',
            lower_loop: 'Lower loop',
            mass: 'Mass',
            mass_brine: 'Mass of brine',
            mass_salt: 'Mass of salt',
            mass_sand: 'Mass of sand',
            maximum_wind_speed: 'Maximum wind speed',
            menu: 'Menu',
            moisture: 'Moisture',
            navigation: 'Navigation',
            networkChart: 'Signal Quality',
            newAsset: 'New Tracker',
            notifications: 'Notifications',
            odometer: 'Odometer',
            potential: 'Potential',
            power: 'Total',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            precipitation: 'Precipitation',
            precipitation_absolute: 'Precipitation absolute',
            precipitation_intensity: 'Precipitation intensity',
            roadMaintenance_humidity: 'Air humidity',
            roadMaintenance_temperature_0: 'Air temperature',
            roadMaintenance_temperature_1: 'Surface temperature',
            roadMaintenance_temperature_2: 'Sensor head temperature',
            roadMaintenance_temperature_3: 'Dew point',
            runningTimeChart: 'Running Time',
            running_time: 'Running Time',
            saltVehicleMassCharts: 'Mass spread',
            sbbbinchart: 'Fill level',
            search: 'Search',
            settings: 'Settings',
            signal: 'Signal',
            solar_radiation: 'Solar radiation',
            speed: 'Speed',
            spread_density_salt: 'Spread density of salt',
            spread_density_sand: 'Spread density of sand',
            statuschartview: 'State',
            t1: 'Inlet temperature',
            t2: 'Outlet temperature',
            t3: 'Room temperature',
            t4: 'Outside temperature',
            t5: 't5',
            t6: 't6',
            tachograph: 'Tachograph',
            tachograph_odometer: 'Odometer',
            tau: 'Tau',
            temperature: 'Temperature',
            timeline: 'Trip history timeline',
            tracking: 'Tracking',
            treehistorymoisturechart: 'Moisture',
            treehistorytemperaturechart: 'Temperature',
            triphistory: 'Trip History',
            upper_loop: 'Upper loop',
            vapor_pressure: 'Vapor pressure',
            voc: 'Volatile organic compounds',
            voltage_0: 'Voltage - Channel 1',
            voltage_1: 'Voltage - Channel 2',
            volume: 'Volume',
            water_level: 'Water level',
            wind_direction: 'Wind direction',
            wind_speed: 'Wind speed',
        },
    },
    de: {
        shared: {
            language: {
                de: 'Deutsch',
                en: 'Englisch',
                fr: 'French',
                it: 'Italienisch',
            },
            measurementOptions: {
                battery_status: {
                    critical: 'Kritisch',
                    ok: 'Ok',
                    warning: 'Warnung',
                },
                button_mode: {
                    1: 'Kurz drücken',
                    2: 'Langes drücken',
                    3: 'Doppelt drücken',
                },
            },
            measurements: {
                adblue_level: 'AdBlue-Füllstand',
                ai1: 'Analogeingang - Kanal 1',
                ai2: 'Analogeingang - Kanal 2',
                ai3: 'Analogeingang - Kanal 3',
                ai4: 'Analogeingang - Kanal 4',
                ai5: 'Analogeingang - Kanal 5',
                ai6: 'Analogeingang - Kanal 6',
                ai7: 'Analogeingang - Kanal 7',
                ai8: 'Analogeingang - Kanal 8',
                altitude: 'Höhe',
                angle: 'Winkel',
                barometric_pressure: 'Barometrischer Luftdruck',
                battery: 'Batterie',
                battery_status: 'Battery status',
                battery_voltage: 'Batteriespannung',
                brightness: 'Helligkeit',
                button_mode: 'Tastendruck',
                co2: 'CO2',
                custom: 'Benutzerdefiniert',
                d1: 'Zustand - Kanal 1',
                d2: 'Zustand - Kanal 2',
                d3: 'Zustand - Kanal 3',
                d4: 'Zustand - Kanal 4',
                d5: 'Zustand - Kanal 5',
                d6: 'Zustand - Kanal 6',
                distance: 'Distanz',
                distance_to_service: 'Distanz bis zum Service',
                dtc_codes: 'DTC Codes',
                energy_0: 'Energie',
                ev_battery_health: 'EV Batterie Zustand',
                ev_battery_percentage: 'EV Batterieladung',
                ev_charging_state: 'EV Ladezustand',
                ignition: 'Zündung',
                movement: 'Movement',
                ev_remaining_distance: 'EV verbleibende Entfernung',
                external_voltage: 'Externe Spannung',
                fill_level: 'Füllstand',
                fuel_consumption: 'Kraftstoffverbrauch',
                fuel_level: 'Kraftstoffstand',
                fuel_rate: 'Kraftstoffmenge',
                fuel_total: 'Gesamter Kraftstoffverbrauch',
                geofence: 'Geofence',
                humidity: 'Relative Luftfeuchtigkeit',
                humidity_absolute: 'Absolute Luftfeuchtigkeit',
                level: 'Pegel',
                lightning_average_distance:
                    'Durchschnittliche Entfernung der Blitze',
                lightning_strike_count: 'Anzahl der Blitzeinschläge',
                lower_loop: 'Untere Schlaufe',
                maintenance: 'Wartung',
                mass: 'Masse',
                mass_brine: 'Masse Sole',
                mass_salt: 'Masse Salz',
                mass_sand: 'Masse Splitt',
                maximum_wind_speed: 'Maximale Windgeschwindigkeit',
                moisture: 'Feuchtigkeit',
                odometer: 'Kilometerzähler',
                offline: 'Offline',
                online: 'Online',
                potential: 'Potential',
                power: 'Leistung',
                precipitation: 'Niederschlag',
                precipitation_absolute: 'Niederschlag absolut',
                precipitation_intensity: 'Niederschlagsintensität',
                probe_state: 'Sondenstatus',
                regex: 'Regex',
                running_time: 'Betriebszeit',
                sensor_attached: 'Sensor angeschlossen',
                solar_radiation: 'Sonneneinstrahlung',
                speed: 'Geschwindigkeit',
                spread_density_brine: 'Streudichte von Sole',
                spread_density_salt: 'Streudichte von Salz',
                spread_density_sand: 'Streudichte von Splitt',
                spread_distance: 'Kilometerstand der Streuung',
                spread_running_time: 'Betriebszeit',
                spread_width: 'Streubreite',
                spreading_on: 'Zustand des Streuers',
                state: 'Zustand',
                t1: 'Temperatur - Kanal 1',
                t2: 'Temperatur - Kanal 2',
                t3: 'Temperatur - Kanal 3',
                t4: 'Temperatur - Kanal 4',
                t5: 'Temperatur - Kanal 5',
                t6: 'Temperatur - Kanal 6',
                tachograph_odometer: 'Kilometerzähler Tachograph',
                tau: 'Tau',
                temperature: 'Temperatur',
                upper_loop: 'Obere Schlaufe',
                vapor_pressure: 'Wasserdampfdruck',
                voc: 'VOC',
                voltage: 'Spannung',
                voltage_0: 'Spannung - Kanal 1',
                voltage_1: 'Spannung - Kanal 2',
                voltage_2: 'Spannung - Kanal 3',
                voltage_3: 'Spannung - Kanal 4',
                voltage_4: 'Spannung - Kanal 5',
                volume: 'Volumen',
                water_level: 'Wasserpegel',
                wind_direction: 'Windrichtung',
                wind_speed: 'Windgeschwindigkeit',
            },
            tachograph: {
                time_related_state: {
                    0: 'Normal',
                    1: '15min vor 4.5h',
                    2: '4.5h erreicht',
                    3: '15min vor 9h',
                    4: '9h erreicht',
                    5: '15min vor 16h',
                    6: '16h erreicht',
                    7: 'Wochenlenkzeitvorwarnung aktiv',
                    8: 'Wochenlenkzeitwarnung aktiv',
                    9: '2 Wochen Lenkzeitvorwarnung aktiv',
                    10: '2 Wochen Lenkzeitwarnung aktiv',
                    11: 'Warnung vor Ablauf der Fahrerkarte 1 aktiv',
                    12: 'Warnung vor dem nächsten obligatorischen Herunterladen der Fahrerkarte 1 aktiv',
                    13: 'Sonstiges',
                    14: 'Fehler',
                    15: 'Zeitbezogener Zustand',
                },
                working_state: {
                    0: 'Pause',
                    1: 'Fahrer verfügbar',
                    2: 'Arbeit',
                    3: 'Fahrend',
                    6: 'Fehler',
                    7: 'Nicht verfügbar',
                    8: 'Tägliche Pause',
                },
            },
            types: {
                'air-quality': 'Luftqualität',
                'compressor-trailer': 'Kompressoranhänger',
                'default-gasser': 'Diverses',
                'material-container': 'Materialcontainer',
                'parking-space': 'Parkplatz',
                'sbb-bin': 'SBB Recycling Station',
                'sbb-bin-alu': 'SBB Recyclingstation Alu',
                'sbb-bin-paper': 'SBB Recyclingstation Papier',
                'sbb-bin-pet': 'SBB Recyclingstation PET',
                'sbb-bin-waste': 'SBB Recyclingstation Abfall',
                'skip-bin': 'Abfallmulde',
                'team-container': 'Mannschaftscontainer',
                'team-trailer': 'Mannschaftswagen',
                'waste-dump': 'Mulde',
                'wheel-loader': 'Radlader',
                Traktor: 'Traktor',
                Vespa: 'Vespa',
                bicycle: 'Fahrrad',
                bin: 'Abfalleimer',
                boat: 'Boot',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Kompressor',
                dumper: 'Dumper',
                excavator: 'Bagger',
                glasbock: 'Glasbock',
                horse: 'Pferd',
                hund: 'Hund',
                kuh: 'Kuh',
                lama: 'Lama',
                roller: 'Walze',
                schaf: 'Schaf',
                scooter: 'Scooter',
                temperature: 'Temperatur',
                trailer: 'Anhänger',
                truck: 'Lastwagen',
                ziege: 'Ziege',
            },
            weekdays: {
                Mon: 'Mo.',
                Tue: 'Di.',
                Wed: 'Mi.',
                Thu: 'Do.',
                Fri: 'Fr.',
                Sat: 'Sa.',
                Sun: 'So.',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Abbrechen',
            lastMeasurement: 'Letzte Messung',
            no: 'Nein',
            noOptions: 'Keine Optionen verfügbar',
            noSearchResult: 'Kein Suchresultat',
            save: 'Speichern',
            yes: 'Ja',
            success: 'Erfolg',
            error: 'Fehler',
            timeRange: '{from} Uhr - {to} Uhr',
            lsva: {
                tripCreated: 'Fahrt erstellt',
                correctionCreated: 'Korrektur erstellt',
                columns: {
                    type: 'Typ',
                    name: 'Name',
                    vin: 'VIN',
                    duration: 'Dauer',
                    distance: 'Distanz',
                    drives: 'Fahrten',
                    endOfRegistration: 'Registrationsende',
                    message: 'Meldung',
                },
                truckStatus: {
                    ALL: 'Alle',
                    VEHICLE_OUTSIDE_TOLL_DOMAIN: 'Fahrt im Ausland',
                    GNSS_WITH_TRIP: 'Fahrt in der Schweiz',
                    GNSS_WITHOUT_TRIP: 'Keine Fahrt',
                    NO_DRIVE: 'Keine Fahrt',
                    WITHOUT_ACCESS: 'Kein Kontakt mit Tracker',
                },
                trailerTypes: {
                    S: 'Sattelschlepper',
                    T: 'Anhänger',
                },
                tripTypes: {
                    REGULAR: 'Originaleintrag',
                    TRACK: 'Manuell hinzugefügt',
                    JOURNEY: 'Manuell geändert',
                    TRAILER: 'Manuell geändert',
                },
            },
            units: {
                tons: 'Tonnen',
                kg: 'kg',
                km: 'km',
                hour: '{n} Stunde | {n} Stunden',
                minute: '{n} Minute | {n} Minuten',
            },
            reason: 'Grund',
        },
        router: {
            accel_y: 'Leerung',
            adblue_level: 'AdBlue-Füllstand',
            allAssets: 'Alle Assets',
            animalactivitychart: 'Aktivität',
            assetBeaconHistoryChart: 'Beacon Verlauf',
            assetConnectionHeatmap: 'Verbindungsqualität',
            assetGatewayHistoryChart: 'Gateway Verlauf',
            assetLocationHistoryChart: 'Standort Verlauf',
            assetScheme: 'Schema',
            assetTypeAssets: '{assetType} Typ {items}',
            assetTypes: 'Typen von Assets',
            barometric_pressure: 'Barometrischer Luftdruck',
            battery: 'Batterie',
            battery_percentage: 'Ladezustand',
            battery_voltage: 'Batteriespannung',
            brightness: 'Helligkeit',
            changePassword: 'Passwort ändern',
            charts: 'Diagramme',
            chartsCombined: 'Kombinierter Graph',
            clueyLeakageSensors: 'Sensors',
            clueyLeakage_ai2: 'Sensor 1',
            clueyLeakage_ai3: 'Sensor 2',
            clueyLeakage_ai4: 'Sensor 3',
            clueyLeakage_ai5: 'Sensor 4',
            clueyLeakage_ai6: 'Sensor 5',
            clueyLeakage_ai7: 'Sensor 6',
            clueyLeakage_ai8: 'Sensor 7',
            cm2humiditychart: 'Temperatur und Feuchtigkeit',
            co2: 'Kohlenstoffdioxid',
            connectionHeatmap: 'Verbindungsqualität',
            createlocation: 'Neuer Standort',
            current: 'Silo',
            d1: 'Kanal 1',
            d2: 'Kanal 2',
            d3: 'Kanal 3',
            dashboardSettings: 'Einstellungen',
            detail: 'Details',
            distance: 'Distanz',
            dynamicbinchart: 'Füllstand',
            ecocoach_d1_count: 'Power up',
            ecocoach_energy_0: 'Restliche Energie',
            ecocoach_temperature_0: 'Batterietemperatur',
            ecocoach_temperature_1: 'AFE Temperatur',
            ecocoach_voltage_0: 'Batteriespannung',
            editAsset: 'Asset bearbeiten',
            editlocation: 'Standort bearbeiten',
            energy_0: 'Energie',
            ev_battery_health: 'EV Batterie Zustand',
            ev_battery_percentage: 'EV Batterieladung',
            ev_charging_state: 'EV Ladezustand',
            ignition: 'Zündung',
            movement: 'Movement',
            external_voltage: 'Externe Spannung',
            fenceGuardStatusChart: 'Spannung Weidezaun',
            fenceGuardVoltage: 'Spannung Weidezaun',
            fenceGuard_voltage_0: 'Litze 1',
            fenceGuard_voltage_1: 'Litze 2',
            fenceGuard_voltage_2: 'Litze 3',
            fenceGuard_voltage_3: 'Litze 4',
            fenceGuard_voltage_4: 'Litze 5',
            fencyBoy_d1_count: 'Impulse',
            fillLevel: 'Füllstand',
            fill_level: 'Füllstand',
            fuel_consumption: 'Kraftstoffverbrauch',
            fuel_level: 'Kraftstoffstand',
            fuel_rate: 'Kraftstoffmenge',
            globalAnimalActivityChart: 'Aktivität',
            humidity: 'Relative Luftfeuchtigkeit',
            humidity_absolute: 'Absolute Luftfeuchtigkeit',
            level: 'Pegel',
            lightning_average_distance:
                'Durchschnittliche Entfernung der Blitze',
            lightning_strike_count: 'Anzahl der Blitzeinschläge',
            location: 'Standort',
            locationAnimalActivityChart: 'Aktivität',
            locationConnectionHeatmap: 'Verbindungsqualität',
            locationDetailAssetDetail: 'Details',
            locationHistoryChart: 'Standort Verlauf',
            locationhistory: 'Verlauf',
            lostAssets: 'Zone verlassen',
            lowBatteryAssets: 'Niedriger Batteriestand',
            lower_loop: 'Untere Schlaufe',
            mass: 'Masse',
            mass_brine: 'Masse Sole',
            mass_salt: 'Masse Salz',
            mass_sand: 'Masse Splitt',
            maximum_wind_speed: 'Maximale Windgeschwindigkeit',
            menu: 'Menü',
            moisture: 'Feuchtigkeit',
            navigation: 'Navigation',
            networkChart: 'Signal Qualität',
            newAsset: 'Neuer Tracker',
            notifications: 'Benachrichtigungen',
            odometer: 'Kilometerzähler',
            potential: 'Potential',
            power: 'Total',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            precipitation: 'Niederschlag',
            precipitation_absolute: 'Niederschlag absolut',
            precipitation_intensity: 'Niederschlagsintensität',
            roadMaintenance_humidity: 'Luftfeuchtigkeit',
            roadMaintenance_temperature_0: 'Lufttemperatur',
            roadMaintenance_temperature_1: 'Oberflächentemperatur',
            roadMaintenance_temperature_2: 'Temperatur des Sensorkopfes',
            roadMaintenance_temperature_3: 'Taupunkt',
            runningTimeChart: 'Betriebsstunden',
            running_time: 'Betriebsstunden',
            saltVehicleMassCharts: 'Gestreute Masse',
            sbbbinchart: 'Füllstand',
            search: 'Suche',
            settings: 'Einstellungen',
            signal: 'Signal',
            solar_radiation: 'Sonneneinstrahlung',
            speed: 'Geschwindigkeit',
            spread_density_salt: 'Streudichte von Salz',
            spread_density_sand: 'Streudichte von Splitt',
            statuschartview: 'Zustand',
            t1: 'Vorlauftemperatur',
            t2: 'Rücklauftemperatur',
            t3: 'Raumtemperatur',
            t4: 'Aussentemperatur',
            t5: 't5',
            t6: 't6',
            tachograph: 'Tachograph',
            tachograph_odometer: 'Kilometerzähler',
            tau: 'Tau',
            temperature: 'Temperatur',
            timeline: 'Zeitleiste Reiseverlauf',
            tracking: 'Tracking',
            treehistorymoisturechart: 'Feuchtigkeit',
            treehistorytemperaturechart: 'Temperatur',
            triphistory: 'Fahrtenverlauf',
            upper_loop: 'Obere Schlaufe',
            vapor_pressure: 'Wasserdampfdruck',
            voc: 'Flüchtige organische Verbindungen',
            voltage_0: 'Spannung - Kanal 1',
            voltage_1: 'Spannung - Kanal 2',
            volume: 'Volumen',
            water_level: 'Wasserpegel',
            wind_direction: 'Windrichtung',
            wind_speed: 'Windgeschwindigkeit',
        },
    },
    fr: {
        shared: {
            language: {
                de: 'Allemand',
                en: 'Anglais',
                fr: 'Français',
                it: 'Italien',
            },
            measurementOptions: {
                battery_status: {
                    critical: 'Critique',
                    ok: 'Ok',
                    warning: 'Attention',
                },
                button_mode: {
                    1: 'Presse courte',
                    2: 'Pression longue',
                    3: 'Double pression',
                },
            },
            measurements: {
                adblue_level: "Niveau d'AdBlue",
                ai1: 'Entrée analogique - Canal 1',
                ai2: 'Entrée analogique - Canal 2',
                ai3: 'Entrée analogique - Canal 3',
                ai4: 'Entrée analogique - Canal 4',
                ai5: 'Entrée analogique - Canal 5',
                ai6: 'Entrée analogique - Canal 6',
                ai7: 'Entrée analogique - Canal 7',
                ai8: 'Entrée analogique - Canal 8',
                altitude: 'Altitude',
                angle: 'Angle',
                barometric_pressure: 'Pression barométrique',
                battery: 'Batterie',
                battery_status: 'Etat de la batterie',
                battery_voltage: 'Tension de la batterie',
                brightness: 'Luminosité',
                button_mode: 'Entrée de bouton',
                co2: 'CO2',
                custom: 'Personnalisé',
                d1: 'Etat - Canal 1',
                d2: 'Etat - Canal 2',
                d3: 'Etat - Canal 3',
                d4: 'Etat - Canal 4',
                d5: 'Etat - Canal 5',
                d6: 'Etat - Canal 6',
                distance: 'Distance',
                distance_to_service: 'Distance du service',
                dtc_codes: 'DTC Codes',
                energy_0: 'Energie',
                ev_battery_health: 'EV état de la batterie',
                ev_battery_percentage: 'EV pourcentage de la batterie',
                ev_charging_state: 'EV état de charge',
                ignition: 'Allumage',
                movement: 'Mouvement',
                ev_remaining_distance: 'EV distance restante',
                external_voltage: 'Voltaggio externe',
                fill_level: 'Niveau de remplissage',
                fuel_consumption: 'Consommation de carburant',
                fuel_level: 'Niveau de carburant',
                fuel_rate: 'Taux de carburant',
                fuel_total: 'Consommation totale de carburant',
                geofence: 'Geofence',
                humidity: 'Humidité relative',
                humidity_absolute: 'Humidité absolue',
                level: 'Niveau',
                lightning_average_distance: 'Distance moyenne des éclairs',
                lightning_strike_count: 'Nombre de coups de foudre',
                lower_loop: 'Boucle inférieure',
                maintenance: 'Maintenance',
                mass: 'Masse',
                mass_brine: 'Masse de saumure',
                mass_salt: 'Masse de sel',
                mass_sand: 'Masse de sable',
                maximum_wind_speed: 'Vitesse maximale du vent',
                moisture: 'Humidité',
                odometer: 'Compteur kilométrique',
                offline: 'Offline',
                online: 'Online',
                potential: 'Potentiel',
                power: 'Puissance',
                precipitation: 'Précipitations',
                precipitation_absolute: 'Précipitations absolues',
                precipitation_intensity: 'Intensité des précipitations',
                probe_state: 'Etat de la sonde',
                regex: 'Regex',
                running_time: 'Temps de fonctionnement',
                sensor_attached: 'Capteur attaché',
                solar_radiation: 'Rayonnement solaire',
                speed: 'Vitesse',
                spread_density_brine: "Densité de l'épandage de la saumure",
                spread_density_salt: "Densité de l'épandage du sel",
                spread_density_sand: "Densité de l'épandage du sable",
                spread_distance: "Kilométrage de l'épandage",
                spread_running_time: 'Durée de fonctionnement',
                spread_width: "Largeur de l'épandage",
                spreading_on: "Etat de l'épandage",
                state: 'Etat',
                t1: 'Température - Channel 1',
                t2: 'Température - Channel 2',
                t3: 'Température - Channel 3',
                t4: 'Température - Channel 4',
                t5: 'Température - Channel 5',
                t6: 'Température - Channel 6',
                tachograph_odometer: 'Compteur kilométrique tachygraphique',
                tau: 'Tau',
                temperature: 'Température',
                upper_loop: 'Boucle supérieure',
                vapor_pressure: 'Pression de vapeur',
                voc: 'VOC',
                voltage: 'Tension',
                voltage_0: 'Tension - Channel 1',
                voltage_1: 'Tension - Channel 2',
                voltage_2: 'Tension - Channel 3',
                voltage_3: 'Tension - Channel 4',
                voltage_4: 'Tension - Channel 5',
                volume: 'Volume',
                water_level: "Niveau de l'eau",
                wind_direction: 'Direction du vent',
                wind_speed: 'Vitesse du vent',
            },
            tachograph: {
                time_related_state: {
                    0: 'Normal',
                    1: '15min avant 4.5h',
                    2: '4.5h atteint',
                    3: '15min avant 9h',
                    4: '9h atteint',
                    5: '15min avant 16h',
                    6: '16h atteint',
                    7: 'Pré-alerte de temps de conduite hebdomadaire active',
                    8: 'Avertissement de temps de conduite hebdomadaire actif',
                    9: 'Pré-alerte de temps de conduite de 2 semaines active',
                    10: 'Avertissement de temps de conduite de 2 semaines actif',
                    11: "Avertissement d'expiration de la carte de conducteur 1 actif",
                    12: 'Avertissement de téléchargement de la prochaine carte de conducteur 1 obligatoire actif',
                    13: 'Autre',
                    14: 'Erreur',
                    15: 'État lié au temps',
                },
                working_state: {
                    0: 'Repos',
                    1: 'Chauffeur disponible',
                    2: 'Travail',
                    3: 'Conduite',
                    6: 'Erreur',
                    7: 'Non disponible',
                    8: 'Repos quotidien',
                },
            },
            types: {
                'air-quality': "Qualité de l'air",
                'compressor-trailer': 'Remorque pour compresseur',
                'default-gasser': 'Divers',
                'material-container': 'Conteneur de matériaux',
                'parking-space': 'Place de parking',
                'sbb-bin': 'Station de recyclage des CFF',
                'sbb-bin-alu': 'Station de recyclage aluminium des CFF',
                'sbb-bin-paper': 'Station de recyclage papier des CFF',
                'sbb-bin-pet': 'Station de recyclage PET des CFF',
                'sbb-bin-waste': 'Déchets de la station de recyclage des CFF',
                'skip-bin': 'Benne à déchets',
                'team-container': "Conteneur d'équipe",
                'team-trailer': "Voiture d'équipe",
                'waste-dump': 'Dépôt de déchets',
                'wheel-loader': 'Chargeur sur roue',
                Traktor: 'Tracteur',
                Vespa: 'Vespa',
                bicycle: 'Bicyclette',
                bin: 'Poubelle',
                boat: 'Bateau',
                bus: 'Bus',
                car: 'Voiture',
                compressor: 'Compresseur',
                dumper: 'Dumper',
                excavator: 'Pelleteuse',
                glasbock: 'Palette de verre',
                horse: 'Cheval',
                hund: 'Chien',
                kuh: 'Vache',
                lama: 'Lama',
                roller: 'Rouleau',
                schaf: 'Mouton',
                scooter: 'Scooter',
                temperature: 'Température',
                trailer: 'Remorque',
                truck: 'Camion',
                ziege: 'Chèvre',
            },
            weekdays: {
                Mon: 'Lun',
                Tue: 'Mar',
                Wed: 'Mer',
                Thu: 'Jeu',
                Fri: 'Ven',
                Sat: 'Sam',
                Sun: 'Dim',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Annuler',
            lastMeasurement: 'Dernière mesure',
            no: 'Non',
            noOptions: 'Aucune option disponible',
            noSearchResult: 'Aucun résultat de recherche',
            save: 'Sauvegarder',
            yes: 'Oui',
            success: 'Succès',
            error: 'Erreur',
            timeRange: '{from} - {to}',
            lsva: {
                tripCreated: 'Trajet créé',
                correctionCreated: 'Correction créée',
                columns: {
                    type: 'Type',
                    name: 'Name',
                    vin: 'VIN',
                    duration: 'Durée',
                    distance: 'Distance',
                    drives: 'Trajets',
                    endOfRegistration: "Fin de l'enregistrement",
                    message: 'Message',
                },
                truckStatus: {
                    ALL: 'Tous',
                    VEHICLE_OUTSIDE_TOLL_DOMAIN: "Conduite à l'étranger",
                    GNSS_WITH_TRIP: 'Conduite en Suisse',
                    GNSS_WITHOUT_TRIP: 'Aucune conduite',
                    NO_DRIVE: 'Aucune conduite',
                    WITHOUT_ACCESS: 'Aucun contact avec le tracker',
                },
                trailerTypes: {
                    S: 'Semi-remorque',
                    T: 'Remorque',
                },
                tripTypes: {
                    REGULAR: 'Entrée originale',
                    TRACK: 'Ajout manuel',
                    TRAILER: 'Modification manuelle',
                    JOURNEY: 'Modification manuelle',
                },
            },
            units: {
                tons: 'tones',
                kg: 'kg',
                km: 'km',
                hour: '{n} heure | {n} heures',
                minute: '{n} minute | {n} minutes',
            },
            reason: 'Raison',
        },
        router: {
            accel_y: 'Collection',
            adblue_level: "Niveau d'AdBlue",
            allAssets: 'Tous les assets',
            animalactivitychart: 'Activité',
            assetBeaconHistoryChart: 'Historique de Beacon',
            assetConnectionHeatmap: 'Qualité de la connexion',
            assetGatewayHistoryChart: 'Historique de Gateway',
            assetLocationHistoryChart: "Historique de l'emplacement",
            assetScheme: 'Schéma',
            assetTypeAssets: '{items} del tipo {assetType}',
            assetTypes: "Types d'assets",
            barometric_pressure: 'Pression barométrique',
            battery: 'Batterie',
            battery_percentage: 'Etat de la charge',
            battery_voltage: 'Tension de la batterie',
            brightness: 'Luminosité',
            changePassword: 'Modifier le mot de passe',
            charts: 'Graphiques',
            chartsCombined: 'Graphique combiné',
            clueyLeakageSensors: 'Sensors',
            clueyLeakage_ai2: 'Sensor 1',
            clueyLeakage_ai3: 'Sensor 2',
            clueyLeakage_ai4: 'Sensor 3',
            clueyLeakage_ai5: 'Sensor 4',
            clueyLeakage_ai6: 'Sensor 5',
            clueyLeakage_ai7: 'Sensor 6',
            clueyLeakage_ai8: 'Sensor 7',
            cm2humiditychart: 'Dioxyde de carbone',
            co2: 'Qualité de la connexion',
            connectionHeatmap: 'Température et humidité',
            createlocation: 'Nouvel emplacement',
            current: 'Silo',
            d1: 'Canal 1',
            d2: 'Canal 2',
            d3: 'Canal 3',
            dashboardSettings: 'Paramètres',
            detail: 'Détails',
            distance: 'Distance',
            dynamicbinchart: 'Niveau de remplissage',
            ecocoach_d1_count: 'Power up',
            ecocoach_energy_0: 'Energie restante',
            ecocoach_temperature_0: 'Température de la batterie',
            ecocoach_temperature_1: 'Température AFE',
            ecocoach_voltage_0: 'Tension de la batterie',
            editAsset: "Modifier l'asset",
            editlocation: "Modifier l'emplacement",
            energy_0: 'Energie',
            ev_battery_health: 'EV état de la batterie',
            ev_battery_percentage: 'EV pourcentage de la batterie',
            ev_charging_state: 'EV état de charge',
            ignition: 'Allumage',
            movement: 'Mouvement',
            external_voltage: 'Voltaggio externe',
            fenceGuardStatusChart: 'Tension de la clôture',
            fenceGuardVoltage: 'Tension de la clôture',
            fenceGuard_voltage_0: 'Fil 1',
            fenceGuard_voltage_1: 'Fil 2',
            fenceGuard_voltage_2: 'Fil 3',
            fenceGuard_voltage_3: 'Fil 4',
            fenceGuard_voltage_4: 'Fil 5',
            fencyBoy_d1_count: 'Impulsions',
            fillLevel: 'Niveau de remplissage',
            fill_level: 'Niveau de remplissage',
            fuel_consumption: 'Consommation de carburant',
            fuel_level: 'Niveau de carburant',
            fuel_rate: 'Taux de carburant',
            globalAnimalActivityChart: 'Niveau de remplissage',
            humidity: 'Humidité relative',
            humidity_absolute: 'Humidité absolue',
            level: 'Niveau',
            lightning_average_distance: 'Distance moyenne des éclairs',
            lightning_strike_count: 'Nombre de coups de foudre',
            location: 'Emplacement',
            locationAnimalActivityChart: 'Activité',
            locationConnectionHeatmap: 'Qualité de la connexion',
            locationDetailAssetDetail: 'Détails',
            locationHistoryChart: "Historique de l'emplacement",
            locationhistory: 'Tracé',
            lostAssets: 'Quitter la zone',
            lowBatteryAssets: 'Batterie faible',
            lower_loop: 'Boucle inférieure',
            mass: 'Masse',
            mass_brine: 'Masse de saumure',
            mass_salt: 'Masse de sel',
            mass_sand: 'Masse de sable',
            maximum_wind_speed: 'Vitesse maximale du vent',
            menu: 'Menu',
            moisture: 'Humidité',
            navigation: 'Navigation',
            networkChart: 'Qualité du signal',
            newAsset: 'Nouveau tracker',
            notifications: 'Notifications',
            odometer: 'Compteur kilométrique',
            potential: 'Potentiel',
            power: 'Total',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            precipitation: 'Précipitations',
            precipitation_absolute: 'Précipitations absolues',
            precipitation_intensity: 'Intensité des précipitations',
            roadMaintenance_humidity: "Humidité de l'air",
            roadMaintenance_temperature_0: "Température de l'air",
            roadMaintenance_temperature_1: 'Température de surface',
            roadMaintenance_temperature_2: 'Température de la tête du capteur',
            roadMaintenance_temperature_3: 'Point de rosée',
            runningTimeChart: 'Temps de fonctionnement',
            running_time: 'Temps de fonctionnement',
            saltVehicleMassCharts: 'Masse épandue',
            sbbbinchart: 'Niveau de remplissage',
            search: 'Recherche',
            settings: 'Paramètres',
            signal: 'Signal',
            solar_radiation: 'Rayonnement solaire',
            speed: 'Vitesse',
            spread_density_salt: "Densité de l'épandage du sel",
            spread_density_sand: "Densité de l'épandage du sable",
            statuschartview: 'Etat',
            t1: "Température d'entrée",
            t2: 'Température de sortie',
            t3: 'Température ambiante',
            t4: 'Température extérieure',
            t5: 't5',
            t6: 't6',
            tachograph: 'Tachygraphe',
            tachograph_odometer: 'Compteur kilométrique',
            tau: 'Tau',
            temperature: 'Température',
            timeline: 'Chronologie du parcours',
            tracking: 'Tracking',
            treehistorymoisturechart: 'Humidité',
            treehistorytemperaturechart: 'Température',
            triphistory: 'Parcours',
            upper_loop: 'Boucle supérieure',
            vapor_pressure: 'Pression de vapeur',
            voc: 'Composés organiques volatils',
            voltage_0: 'Tension - Canal 1',
            voltage_1: 'Tension - Canal 2',
            volume: 'Volume',
            water_level: "Niveau de l'eau",
            wind_direction: 'Direction du vent',
            wind_speed: 'Vitesse du vent',
        },
    },
    it: {
        shared: {
            language: {
                de: 'Tedesco',
                en: 'Inglese',
                fr: 'French',
                it: 'Italiano',
            },
            measurementOptions: {
                battery_status: {
                    critical: 'Critico',
                    ok: 'Ok',
                    warning: 'Attenzione',
                },
                button_mode: {
                    1: 'Pressione breve',
                    2: 'Pressione lunga',
                    3: 'Doppia pressione',
                },
            },
            measurements: {
                adblue_level: 'Livello di AdBlue',
                ai1: 'Ingresso analogico - Canale 1',
                ai2: 'Ingresso analogico - Canale 2',
                ai3: 'Ingresso analogico - Canale 3',
                ai4: 'Ingresso analogico - Canale 4',
                ai5: 'Ingresso analogico - Canale 5',
                ai6: 'Ingresso analogico - Canale 6',
                ai7: 'Ingresso analogico - Canale 7',
                ai8: 'Ingresso analogico - Canale 8',
                altitude: 'Altitudine',
                angle: 'Angolo',
                barometric_pressure: 'Pressione barometrica',
                battery: 'Batteria',
                battery_status: 'Battery status',
                battery_voltage: 'Tensione della batteria',
                brightness: 'Luminosità',
                button_mode: 'Ingresso a pulsante',
                co2: 'CO2',
                custom: 'Personalizzato',
                d1: 'Stato - Canale 1',
                d2: 'Stato - Canale 2',
                d3: 'Stato - Canale 3',
                d4: 'Stato - Canale 4',
                d5: 'Stato - Canale 5',
                d6: 'Stato - Canale 6',
                distance: 'Distanza',
                distance_to_service: 'Distanza dal servizio',
                dtc_codes: 'DTC Codes',
                energy_0: 'Energia',
                ev_battery_health: 'EV stato della batteria',
                ev_battery_percentage: 'EV percentuale della batteria',
                ev_charging_state: 'EV stato di carica',
                ignition: 'Accensione',
                movement: 'Movimento',
                ev_remaining_distance: 'Distanza residua EV',
                external_voltage: 'Tensione esterna',
                fill_level: 'Livello di riempimento',
                fuel_consumption: 'Consumo di carburante',
                fuel_level: 'Livello del carburante',
                fuel_rate: 'Tasso di carburante',
                fuel_total: 'Consumo totale di carburante',
                geofence: 'Geofence',
                humidity: 'Umidità relativa',
                humidity_absolute: 'Umidità assoluta',
                level: 'Livello',
                lightning_average_distance: 'Distanza media dei fulmini',
                lightning_strike_count: 'Conteggio dei fulmini',
                lower_loop: 'Lower loop',
                maintenance: 'Manutenzione',
                mass: 'Massa',
                mass_brine: 'Massa di salamoia',
                mass_salt: 'Massa di sale',
                mass_sand: 'Massa di sabbia',
                maximum_wind_speed: 'Velocità massima del vento',
                moisture: 'Umidità',
                odometer: 'Contachilometri',
                offline: 'Offline',
                online: 'Online',
                potential: 'Potential',
                power: 'Power',
                precipitation: 'Precipitazioni',
                precipitation_absolute: 'Precipitazioni assolute',
                precipitation_intensity: 'Intensità delle precipitazioni',
                probe_state: 'Stato campione',
                regex: 'Regex',
                running_time: 'Tempo di funzionamento',
                sensor_attached: 'Sensore attaccato',
                solar_radiation: 'Radiazione solare',
                speed: 'Velocità',
                spread_density_brine: 'Densità di spargimento della salamoia',
                spread_density_salt: 'Densità di spargimento del sale',
                spread_density_sand: 'Densità di spargimento della sabbia',
                spread_distance: 'Chilometri di spargimento',
                spread_running_time: 'Tempo di funzionamento',
                spread_width: 'Larghezza di spargimento',
                spreading_on: 'Stato dello spargimento',
                state: 'Stato',
                t1: 'Temperatura - Canale 1',
                t2: 'Temperatura - Canale 2',
                t3: 'Temperatura - Canale 3',
                t4: 'Temperatura - Canale 4',
                t5: 'Temperatura - Canale 5',
                t6: 'Temperatura - Canale 6',
                tachograph_odometer: 'Contachilometri tachigrafo',
                tau: 'Tau',
                temperature: 'Temperatura',
                upper_loop: 'Upper loop',
                vapor_pressure: 'Pressione del vapore',
                voc: 'VOC',
                voltage: 'Voltaggio',
                voltage_0: 'Voltaggio - Canale 1',
                voltage_1: 'Voltaggio - Canale 2',
                voltage_2: 'Voltaggio - Canale 3',
                voltage_3: 'Voltaggio - Canale 4',
                voltage_4: 'Voltaggio - Canale 5',
                volume: 'Volume',
                water_level: "Livello dell'acqua",
                wind_direction: 'Direzione del vento',
                wind_speed: 'Velocità del vento',
            },
            tachograph: {
                time_related_state: {
                    0: 'Normale',
                    1: '15min prima delle 4.5h',
                    2: '4.5h raggiunte',
                    3: '15min prima delle 9h',
                    4: '9h raggiunte',
                    5: '15min prima delle 16h',
                    6: '16h raggiunte',
                    7: 'Preavviso di tempo di guida settimanale attivo',
                    8: 'Avviso di tempo di guida settimanale attivo',
                    9: 'Preavviso del tempo di guida di 2 settimane attivo',
                    10: 'Avviso di tempo di guida di 2 settimane attivo',
                    11: 'Avviso scadenza carta del conducente 1 attivo',
                    12: 'Avviso di scaricamento della prossima carta del conducente obbligatoriamente attivo',
                    13: 'Altro',
                    14: 'Errore',
                    15: 'Stato relativo al tempo',
                },
                working_state: {
                    0: 'Riposo',
                    1: 'Autista disponibile',
                    2: 'Lavoro',
                    3: 'Unità',
                    6: 'Errore',
                    7: 'Non disponibile',
                    8: 'Riposo giornaliero',
                },
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Rimorchio a compressore',
                'default-gasser': 'Varie',
                'material-container': 'Contenitore di materiale',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu':
                    "Stazione di riciclaggio dell'alluminio delle FFS",
                'sbb-bin-paper': 'Carta della stazione di riciclaggio FFS',
                'sbb-bin-pet': 'Stazione di riciclaggio FFS PET',
                'sbb-bin-waste': 'Rifiuti delle stazioni di riciclaggio FFS',
                'skip-bin': 'Skip bin',
                'team-container': 'Team-contenitore',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Discarica',
                'wheel-loader': 'Caricatore a ruota',
                Traktor: 'Trattore',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Compressore',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Rullo',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            weekdays: {
                Mon: 'Lun.',
                Tue: 'Mar.',
                Wed: 'Mer.',
                Thu: 'Gio.',
                Fri: 'Ven.',
                Sat: 'Sab.',
                Sun: 'Dom.',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Staccare',
            lastMeasurement: 'Ultimo misurazione',
            no: 'No',
            noOptions: 'Nessuna opzione disponibile',
            noSearchResult: 'Nessun risultato di ricerca',
            save: 'Salvare',
            yes: 'Si',
            success: 'Successo',
            error: 'Errore',
            timeRange: '{from} - {to}',
            lsva: {
                tripCreated: 'Viaggio creato',
                correctionCreated: 'Correzione creata',
                columns: {
                    type: 'Type',
                    name: 'NOME',
                    vin: 'VIN',
                    duration: 'Durata',
                    distance: 'Distanza',
                    drives: 'Viaggi',
                    endOfRegistration: 'Fine registrazione',
                    message: 'Messaggio',
                },
                truckStatus: {
                    ALL: 'Tutti',
                    VEHICLE_OUTSIDE_TOLL_DOMAIN: "Guida all'estero",
                    GNSS_WITH_TRIP: 'Guida in Svizzera',
                    GNSS_WITHOUT_TRIP: 'Nessuna guida',
                    NO_DRIVE: 'Nessuna guida',
                    WITHOUT_ACCESS: 'Nessun contatto con il tracker',
                },
                trailerTypes: {
                    S: 'Semitrailer',
                    T: 'Trailer',
                },
                tripTypes: {
                    REGULAR: 'Inserimento originale',
                    TRACK: 'Aggiunta manuale',
                    JOURNEY: 'Aggiunta manuale',
                    TRAILER: 'Modifica manuale',
                },
            },
            units: {
                tons: 'tonnellate',
                kg: 'kg',
                km: 'km',
                hour: '{n} ora | {n} ore',
                minute: '{n} minuto | {n} minuti',
            },
            reason: 'Ragione',
        },
        router: {
            accel_y: 'Collezione',
            adblue_level: 'Livello di AdBlue',
            allAssets: 'Tutti Assets',
            animalactivitychart: 'Attività',
            assetBeaconHistoryChart: 'Storia di Beacon',
            assetConnectionHeatmap: 'Qualità della connessione',
            assetGatewayHistoryChart: 'Storia di Gateway',
            assetLocationHistoryChart: 'Storico Locazione',
            assetScheme: 'Schema',
            assetTypeAssets: '{items} del tipo {assetType}',
            assetTypes: 'Tipi di assets',
            barometric_pressure: 'Pressione barometrica',
            battery: 'Batteria',
            battery_percentage: 'State of Charge',
            battery_voltage: 'Tensione della batteria',
            brightness: 'Luminosità',
            changePassword: 'Modificare Password',
            charts: 'Grafici',
            chartsCombined: 'Grafico combinato',
            clueyLeakageSensors: 'Sensors',
            clueyLeakage_ai2: 'Sensor 1',
            clueyLeakage_ai3: 'Sensor 2',
            clueyLeakage_ai4: 'Sensor 3',
            clueyLeakage_ai5: 'Sensor 4',
            clueyLeakage_ai6: 'Sensor 5',
            clueyLeakage_ai7: 'Sensor 6',
            clueyLeakage_ai8: 'Sensor 7',
            cm2humiditychart: 'Temperatura e umidità',
            co2: 'Anidride carbonica',
            connectionHeatmap: 'Qualità della connessione',
            createlocation: 'Nuovo Locazione',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            d3: 'Channel 3',
            dashboardSettings: 'Impostazione',
            detail: 'Dettaglio',
            distance: 'Distanza',
            dynamicbinchart: 'Livello di riempimento',
            ecocoach_d1_count: 'Power up',
            ecocoach_energy_0: 'Energia residua',
            ecocoach_temperature_0: 'Battery temperatura',
            ecocoach_temperature_1: 'AFE temperatura',
            ecocoach_voltage_0: 'Livello dell batteria',
            editAsset: 'Modificare Asset',
            editlocation: 'Modificare Locazione',
            energy_0: 'Energia',
            ev_battery_health: 'EV stato della batteria',
            ev_battery_percentage: 'EV percentuale della batteria',
            ev_charging_state: 'EV stato di carica',
            ignition: 'Accensione',
            movement: 'Movimento',
            external_voltage: 'Tensione esterna',
            fenceGuardStatusChart: 'Voltaggio Recinzione',
            fenceGuardVoltage: 'Voltaggio Recinzione',
            fenceGuard_voltage_0: 'Filo 1',
            fenceGuard_voltage_1: 'Filo 2',
            fenceGuard_voltage_2: 'Filo 3',
            fenceGuard_voltage_3: 'Filo 4',
            fenceGuard_voltage_4: 'Filo 5',
            fencyBoy_d1_count: 'Impulsi',
            fillLevel: 'Livello di riempimento',
            fill_level: 'Livello di riempimento',
            fuel_consumption: 'Consumo di carburante',
            fuel_level: 'Livello del carburante',
            fuel_rate: 'Tasso di carburante',
            globalAnimalActivityChart: 'Attività',
            humidity: 'Umidità relativa',
            humidity_absolute: 'Umidità assoluta',
            level: 'Livello',
            lightning_average_distance: 'Distanza media dei fulmini',
            lightning_strike_count: 'Conteggio dei fulmini',
            location: 'Locazione',
            locationAnimalActivityChart: 'Attività',
            locationConnectionHeatmap: 'Qualità della connessione',
            locationDetailAssetDetail: 'Dettaglio',
            locationHistoryChart: 'Storico Locazione',
            locationhistory: 'Storico',
            lostAssets: 'Lascia la zona',
            lowBatteryAssets: 'Basso Batteria',
            lower_loop: 'Lower loop',
            mass: 'Massa',
            mass_brine: 'Massa di salamoia',
            mass_salt: 'Massa di sale',
            mass_sand: 'Massa di sabbia',
            maximum_wind_speed: 'Velocità massima del vento',
            menu: 'Menu',
            moisture: 'Umidità',
            navigation: 'Navigation',
            networkChart: 'Qualità del segnale',
            newAsset: 'Nuovo Asset',
            notifications: 'Notificazione',
            odometer: 'Contachilometri',
            potential: 'Potential',
            power: 'Totalmente',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            precipitation: 'Precipitazioni',
            precipitation_absolute: 'Precipitazioni assolute',
            precipitation_intensity: 'Intensità delle precipitazioni',
            roadMaintenance_humidity: "Umidità dell'aria",
            roadMaintenance_temperature_0: "Temperatura dell'aria",
            roadMaintenance_temperature_1: 'Temperatura di superficie',
            roadMaintenance_temperature_2:
                'Temperatura della testa del sensore',
            roadMaintenance_temperature_3: 'Punto di rugiada',
            runningTimeChart: 'Tempo di Esecuzione',
            running_time: 'Tempo di Esecuzione',
            saltVehicleMassCharts: 'Massa sparsa',
            sbbbinchart: 'Livello di riempimento',
            search: 'Ricerca',
            settings: 'Impostazione',
            signal: 'Signal',
            solar_radiation: 'Radiazione solare',
            speed: 'Velocità',
            spread_density_salt: 'Densità di spargimento del sale',
            spread_density_sand: 'Densità di spargimento della sabbia',
            statuschartview: 'Stato',
            t1: 'Temperatura di mandata',
            t2: 'Temperatura di ritorno',
            t3: 'Temperatura ambiente',
            t4: 'Temperatura esterna',
            t5: 't5',
            t6: 't6',
            tachograph: 'Tachigrafo',
            tachograph_odometer: 'Contachilometri',
            tau: 'Tau',
            temperature: 'Temperatura',
            timeline: 'Cronologia del viaggio',
            tracking: 'Tracking',
            treehistorymoisturechart: 'Umidità',
            treehistorytemperaturechart: 'Temperatura',
            triphistory: 'Percorso di viaggio',
            upper_loop: 'Upper loop',
            vapor_pressure: 'Pressione del vapore',
            voc: 'Composti organici volatili',
            voltage_0: 'Tensione - Canale 1',
            voltage_1: 'Tensione - Canale 2',
            volume: 'Volume',
            water_level: "Livello dell'acqua",
            wind_direction: 'Direzione del vento',
            wind_speed: 'Velocità del vento',
        },
    },
}
