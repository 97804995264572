var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.textarea)?_c('textarea',_vm._g(_vm._b({ref:"input",staticClass:"base-input",class:{
            'base-input--block': _vm.block,
        }},'textarea',_vm.$attrs,false),_vm.inputListeners)):_c('label',{staticClass:"base-input",class:{
            'base-input--block': _vm.block,
            'base-input--clearable': _vm.clearable,
            'base-input--disabled': _vm.$attrs.disabled,
            'base-input--error': _vm.isError,
            'base-input--with-icon': _vm.$slots.icon,
            'base-input--with-postfix': _vm.$slots.postfix,
            'base-input--with-prefix': _vm.$slots.prefix,
        }},[_vm._t("icon"),(_vm.$slots.prefix)?_c('span',{staticClass:"base-input__prefix"},[_vm._t("prefix")],2):_vm._e(),_c('input',_vm._g(_vm._b({ref:"input"},'input',_vm.$attrs,false),_vm.inputListeners)),(_vm.clearable)?_c('span',{staticClass:"base-input__clear"},[_c('RemoveIcon',{attrs:{"width":"1em","height":"1em"},on:{"click":_vm.handleClear}})],1):_vm._e(),(_vm.$slots.postfix)?_c('span',{staticClass:"base-input__postfix"},[_vm._t("postfix")],2):_vm._e()],2),(_vm.isError && _vm.errorText)?_c('div',{staticClass:"base-input__error-text"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }