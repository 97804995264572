var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"base-button",class:{
        'base-button--block': _vm.block,
        'base-button--danger': _vm.variant === 'danger',
        'base-button--ghost': _vm.variant === 'ghost',
        'base-button--outline': _vm.variant === 'outline',
        'base-button--small': _vm.size === 'small',
        'base-button--with-icon':
            _vm.$slots.icon ||
            _vm.isDoneIconVisible ||
            _vm.isFailIconVisible ||
            _vm.isLoading,
    },attrs:{"disabled":_vm.disabled || _vm.isLoading}},_vm.$listeners),[(_vm.isLoading)?_c('VSpinner',{staticClass:"base-button__icon",attrs:{"size":"18","line-bg-color":_vm.colorIcon,"line-fg-color":_vm.colorSpinner,"line-size":2,"speed":1}}):(_vm.isDoneIconVisible)?_c('CheckIcon',{staticClass:"base-button__icon",attrs:{"width":"18","height":"18","color":_vm.colorIcon}}):(_vm.isFailIconVisible)?_c('RemoveIcon',{staticClass:"base-button__icon",attrs:{"width":"18","height":"18","color":_vm.colorIcon}}):(_vm.$slots.icon)?_c('div',{staticClass:"l-inline base-button__icon"},[_vm._t("icon")],2):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }